import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { getClosestDate } from './dates';
import { getCharCount } from './string';

export const passwordScore = (password) => {
  const matchedCase = [];
  matchedCase.push('[$@$!%*#?&]'); // special character
  matchedCase.push('[A-Z]'); // uppercase
  matchedCase.push('[0-9]'); // numbers
  matchedCase.push('[a-z]'); // lowercase

  let score = 0;

  if (password.length > 5) {
    score++;
  }

  matchedCase.forEach((r) => {
    if (new RegExp(r).test(password)) {
      score++;
    }
  });

  return score;
};

export const campaignNameOverwrite = (start_date, finish_date, group_name = '') => {
  const start_moment = moment(start_date);
  const finish_moment = moment(finish_date);
  let campaign_name = '';
  let campaign_group_name = `- ${group_name}`;

  if (group_name === '' || group_name === null) {
    campaign_group_name = ''; // resets group name
  }

  // setting up options to create campaign name
  const current_year = start_moment.format('YYYY');
  const start_month = start_moment.format('MM');
  const start_month_add = start_moment.add(1, 'months').format('MM');
  const finish_month = finish_moment.format('MM');
  const finish_month_add = finish_moment.subtract(1, 'months').format('MM');

  // deciding if the selected date is inside the range +10 || -10 days of the month
  const start_days_of_month = start_moment.date() < start_moment.daysInMonth() - 10;
  const finish_days_of_month = finish_moment.date() > 10;

  // changing the format if it falls inside the month range
  const start_format = start_days_of_month ? start_month : start_month_add;
  const finish_format = finish_days_of_month ? finish_month : finish_month_add;

  // breaking the final string into sections depending on the outcomes above
  if (start_format === finish_format) {
    campaign_name = `${current_year}-${start_format} ${campaign_group_name}`;
  } else if (start_format > finish_format) {
    campaign_name = `${current_year}-${finish_format}/${start_format} ${campaign_group_name}`;
  } else if (start_format < finish_format) {
    campaign_name = `${current_year}-${start_format}/${finish_format} ${campaign_group_name}`;
  }

  return {
    start_date,
    finish_date,
    campaign_name: campaign_name.trim(),
  };
};

export const uniqueIdentifiers = (rows = []) => rows.map((row, i) => uniqueIdentifier(row));

export const uniqueIdentifier = (row) => ({
  ...row,
  uuid: uuidv4(),
});

export const validateChangeOrder = (row) => {
  // api returns null for change_order_strategy_validated means it hasnt finished validation yet
  const { change_order_strategy_validated, workflow_state_id } = row;
  let invalid_strategy = null;
  if (change_order_strategy_validated === null) {
    invalid_strategy = false;
  } else if (!change_order_strategy_validated) {
    invalid_strategy = true;
  }
  if (workflow_state_id !== 2) {
    invalid_strategy = false;
  }
  return invalid_strategy;
};

export const getTextWidth = (text) => {
  const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = '13px Roboto';
  const metrics = context.measureText(text);
  return metrics.width;
};

export const transformRules = (tag_rules) => {
  const mongo_syntax = {};
  for (let i = 0; i < tag_rules.length; i++) {
    const rule = tag_rules[i];
    const { tag_index_name_match, tag_operator_name, tag_rule, tag_rule_array } = rule;
    if (tag_rule || tag_operator_name === '$eq' || tag_operator_name === '$ne') {
      let tag_rule_value = tag_rule;
      if (tag_operator_name.includes('in')) {
        tag_rule_value = tag_rule_array;
      }
      if (mongo_syntax[tag_index_name_match]) {
        mongo_syntax[tag_index_name_match] = {
          ...mongo_syntax[tag_index_name_match],
          [tag_operator_name]: tag_rule_value,
        };
      } else {
        mongo_syntax[tag_index_name_match] = { [tag_operator_name]: tag_rule_value };
      }
    }
  }
  return mongo_syntax;
};

export const reducePixelData = (tag_raw_data = [], tag_rules = []) =>
  tag_raw_data.reduce((acc, next, i) => {
    let rule_exists = true;
    if (i >= 100) {
      return acc;
    }

    for (let i = 0; i < tag_rules.length; i++) {
      const { tag_rule, tag_rule_array = [], tag_operator_name, tag_index_name_match } = tag_rules[i];

      const rule_variable = next[tag_index_name_match];

      switch (tag_operator_name) {
        case '$eq':
          if (rule_variable !== tag_rule) {
            rule_exists = false;
          }
          break;
        case '$nin':
          if (!tag_rule) {
            break;
          }
          if (tag_rule_array.includes(rule_variable)) {
            rule_exists = false;
          }
          break;
        case '$in':
          if (!tag_rule) {
            break;
          }
          if (!tag_rule_array.includes(rule_variable)) {
            rule_exists = false;
          }
          break;
        case '$ne':
          if (rule_variable === tag_rule) {
            rule_exists = false;
          }
          break;
        case '$regex':
          // eslint-disable-next-line no-case-declarations
          const regex = new RegExp(tag_rule);
          if (!regex.test(rule_variable)) {
            rule_exists = false;
          }
          break;
        default:
      }

      if (!rule_exists) {
        break;
      }
    }

    if (rule_exists) {
      for (const [key] of Object.entries(next)) {
        if (!next[key]) {
          delete next[key];
        }
        if (next[key] === '') {
          delete next[key];
        }
        if (key === 'tag_id') {
          delete next[key];
        }
      }
      acc.push(next);
    }
    return acc;
  }, []);

export const getCellBackground = (active) => {
  if (active) {
    return '#e4edf9';
  }
  return false;
};

export const getColorFromRange = (colors, count) => colors[count % colors.length];

export const week_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const getNextDateAndTime = (interval_id, start_date, start_time, bitmask) => {
  const current_date = moment();
  const next_date = moment(start_date);
  const next_time = moment(start_time);

  switch (interval_id) {
    case 1:
      if (current_date.isSame(start_date, 'day')) {
        next_date.set({ hour: current_date.hour() }).add(1, 'hour');
      } else {
        next_date.set({ hour: 0 });
      }
      break;
    case 2:
      if (current_date.isAfter(next_date.set({ hour: next_time.hour(), minute: next_time.minute() }))) {
        next_date.set({
          day: next_date.add(1, 'day').day(),
        });
      }
      next_date.set({
        hour: next_time.hour(),
        minute: next_time.minute(),
      });
      break;
    case 3:
      {
        const available_weekdays = [...bitmask].map((b, i) => (+b === 1 ? i : null)).filter((b) => b !== null);
        const closest_date = getClosestDate(
          next_date.set({ hour: next_time.hour(), minute: next_time.minute() }),
          available_weekdays,
        );
        if (current_date.isAfter(next_date.set({ hour: next_time.hour(), minute: next_time.minute() }))) {
          next_date.set({
            day: next_date.add(1, 'week').day(),
          });
        }
        next_date.set({
          date: closest_date.date(),
          month: closest_date.month(),
          year: closest_date.year(),
          hour: next_time.hour(),
          minute: next_time.minute(),
        });
      }
      break;
    case 4:
      if (current_date.isAfter(next_date.set({ hour: next_time.hour(), minute: next_time.minute() }))) {
        next_date.set({
          month: next_date.add(1, 'month').month(),
        });
      }
      next_date.set({
        hour: next_time.hour(),
        minute: next_time.minute(),
      });
      break;
    default:
      return null;
  }

  return next_date;
};

export const getEntityPage = (pathname) => {
  const segments = pathname.split('/');
  const lastSegment = segments[segments.length - 1];

  if (lastSegment.includes('?')) {
    return lastSegment.split('?')[0];
  }

  return lastSegment;
};
