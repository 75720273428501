import React from 'react';
import { useKeyDownModalEscape } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { ChangePassword } from './ChangePassword';
import { CopyStrategies } from './CopyStrategies';
import { CreateExperiment } from './CreateExperiment';
import { EditModel } from './EditModel';
import { Error } from './Error';
import { ManageAbTest } from './ManageAbTest';
import { ManageAbTestSegment } from './ManageAbTestSegment';
import { ManageAdvertiser } from './ManageAdvertiser';
import { ManageCampaign } from './ManageCampaign';
import { ManageCampaignFrequencyCaps } from './ManageCampaignFrequencyCaps';
import { ManageCampaignGroups } from './ManageCampaignGroups';
import { ManageCreative } from './ManageCreative';
import { ManageDeal } from './ManageDeal';
import { ManageFilter } from './ManageFilter';
import { ManageFlight } from './ManageFlight';
import { ManageLineItem } from './ManageLineItem';
import { ManageList } from './ManageList';
import { ManagePartner } from './ManagePartner';
import { ManageReport } from './ManageReport';
import { ManageSegment } from './ManageSegment';
import { ManageStrategyDeals } from './ManageStrategyDeals';
import { ManageStrategyVendorFees } from './ManageStrategyVendorFees';
import { ManageTag } from './ManageTag';
import { ManageThirdPartyReport } from './ManageThirdPartyReport';
import { ManageUser } from './ManageUser';
import { ManageVendorFee } from './ManageVendorFee';
import { SuccessRequest } from './SuccessRequest';
import { SuccessReset } from './SuccessReset';
import { UploadCreatives } from './UploadCreatives';
import { ViewAbTestSegments } from './ViewAbTestSegments';
import { ViewCampaignPerformance } from './ViewCampaignPerformance';
import { ViewFilterKeywords } from './ViewFilterKeywords';
import { ManageCreativeMessage } from './ManageCreativeMessage';
import { CreateStrategyTemplate } from './CreateStrategyTemplate';

const ModalsWithRouter = ({ history, location, match }) => {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modals, shallowEqual);
  useKeyDownModalEscape(modals, (modal_name) => {
    dispatch(updateModal({ [modal_name]: false }));
  });

  return (
    <>
      <ChangePassword history={history} location={location} match={match} />
      <CreateExperiment history={history} location={location} match={match} />
      <CopyStrategies history={history} location={location} match={match} />
      <ManageAdvertiser history={history} location={location} match={match} />
      <ManageCampaign history={history} location={location} match={match} />
      <ManageVendorFee history={history} location={location} match={match} />
      <ManageTag history={history} location={location} match={match} />
      <ManageFilter history={history} location={location} match={match} />
      <ManageList history={history} location={location} match={match} />
      <ManageSegment history={history} location={location} match={match} />
      <ManageFlight history={history} location={location} match={match} />
      <ManageDeal history={history} location={location} match={match} />
      <ManageThirdPartyReport history={history} location={location} match={match} />
      <ManageAbTest history={history} location={location} match={match} />
      <ManageLineItem history={history} location={location} match={match} />
      <ManageAbTestSegment history={history} location={location} match={match} />
      <ManageCreative history={history} location={location} match={match} />
      <ManageStrategyDeals history={history} location={location} match={match} />
      <ManageStrategyVendorFees history={history} location={location} match={match} />
      <ManageCampaignFrequencyCaps history={history} location={location} match={match} />
      <UploadCreatives history={history} location={location} match={match} />
      <ManageUser history={history} location={location} match={match} />
      <ViewAbTestSegments history={history} location={location} match={match} />
      <EditModel history={history} location={location} match={match} />
      <CreateStrategyTemplate location={location} history={history} />
      <ViewFilterKeywords />
      <ViewCampaignPerformance />
      <SuccessReset />
      <SuccessRequest />
      <Error />
    </>
  );
};

ModalsWithRouter.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export const Modals = withRouter(ModalsWithRouter);
