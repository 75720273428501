import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { formatBudgetSpend, formatNull, formatNumber } from 'utils/numbers';

export const normalizeCampaignPacingPerformance = (rows) => {
  if (rows.length === 0) {
    return {
      suggested_max: 10,
      x: [],
      y1: [],
      y2: [],
      y3: [],
    };
  }

  return rows
    .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
    .reduce(
      (acc, next) => {
        let { suggested_max } = acc;
        const x = formatDate(next.date, { format: 'MMM D' });

        const y1_raw = formatNumber(next.pacing_percentage, { format: 'percentage-multiplied', append: null });
        const y1 = formatNull(y1_raw, null, 0);

        const y2_raw = formatBudgetSpend(next.external_spend_budget_micro_usd, { append: null });
        const y2 = formatNull(y2_raw, null, 0);

        const y3_raw = formatBudgetSpend(next.external_spend_micro_usd, { append: null });
        const y3 = formatNull(y3_raw, null, 0);

        // Comparing which value is higher and setting the max scale
        if (Number(y2) > suggested_max) {
          suggested_max = Number(y2);
        }
        if (Number(y3) > suggested_max) {
          suggested_max = Number(y3);
        }

        return {
          suggested_max,
          x: [...acc.x, x],
          y1: [...acc.y1, y1],
          y2: [...acc.y2, y2],
          y3: [...acc.y3, y3],
        };
      },
      {
        suggested_max: 10,
        x: [],
        y1: [],
        y2: [],
        y3: [],
      },
    );
};
