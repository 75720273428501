import * as constants from 'ducks/types';

export const setTeam = (payload = []) => ({
  type: constants.SET_TEAM,
  payload,
});

export const clearTeam = () => ({
  type: constants.CLEAR_TEAM,
});

export const setTeamTemplates = (payload = []) => ({
  type: constants.SET_TEAM_TEMPLATES,
  payload,
});

export const clearTeamTemplates = () => ({
  type: constants.CLEAR_TEAM_TEMPLATES,
});
