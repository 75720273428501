import { CLEAR_TEAM_TEMPLATES, SET_TEAM_TEMPLATES } from '../types';

export const teamTemplatesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TEAM_TEMPLATES:
      return action.payload;
    case CLEAR_TEAM_TEMPLATES:
      return [];
    default:
      return state;
  }
};
