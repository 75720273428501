import { updateSettings } from 'ducks/actions/settings';
import { setTeam } from 'ducks/actions/teams';
import { normalizeTeam } from 'ducks/normalizers/teams';
import { Gemini } from 'utils/axios';

export const getCampaignTeam = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/${entity_id}/team`);
  const team = normalizeTeam(res.data);
  dispatch(setTeam(team));
  return team;
};

export const updateCampaignTeam = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/team/update`, data);
  return res.data;
};
