import { Button, Dropdown, Input, InputCurrency, InputNumber, InputPercent, Modal, } from '@cognitiv/cyprus-ui';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createCampaignGroup, getAdvertiserCampaignGroups } from 'ducks/operators/campaign-groups';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';

import { getTitanKpi } from './ManageCampaign';
import cn from './Modal.module.scss';

const default_state = {
  campaign_group_name: '',
  campaign_group_id: null,
  measure_type_name: 'CPA',
  measure_type_id: 24,
  measure_class_id: 3,
  measure_unit_id: 2,
};

export const ManageCampaignGroups = ({ match, onCreate }) => {
  const dispatch = useDispatch();

  const { modals, measure_types_list, titan_kpi_types } = useSelector(
    (state) => ({
      modals: state.modals,
      measure_types_list: state.measure_types_list,
      titan_kpi_types: state.titan_kpi_types
    }),
    shallowEqual,
  );

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    setForm({ ...default_state });
  }, []);

  const onChange = (item) => {
    setForm((prev) => ({
      ...prev,
      ...item,
    }));
  };

  const closeModal = () => {
    setForm({ ...default_state });
    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_campaign_group: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { advertiser_id } = match.params;
    try {
      const kpi_type_id = getTitanKpi(form.measure_type_id);

      const data = {
        campaign_group_name: form.campaign_group_name,
        kpi_type_id,
        kpi_benchmark: form.benchmark,
      };

      const campaign_group_id = await dispatch(createCampaignGroup(advertiser_id, data));
      onCreate({
        campaign_group_name: form.campaign_group_name,
        campaign_group_id,
        kpi_type_id,
        kpi_benchmark: form.benchmark,
      });
      dispatch(updateSuccess('campaign group has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    dispatch(updateSettings({ loading: false }));
    closeModal();
  };

  const { campaign_group_name, benchmark_formatted, measure_type_name, measure_unit_id } = form;

  return (
    <Modal
      padding={16}
      close_box={46}
      name="manage_campaign_group"
      show={modals.manage_campaign_group}
      width={450}
      onClose={closeModal}
      standard={false}
    >
      <h3>Create Campaign Group</h3>
      <form onSubmit={onSubmit}>
        <Input
          auto_focus
          label="Campaign Group Name"
          value={campaign_group_name}
          onChange={(value) => onChange({ campaign_group_name: value })}
        />
        <div style={{ display: 'flex' }}>
          <Dropdown
            label="KPI"
            options={measure_types_list.filter((m) => m.measure_class_id === 3)}
            option_key="measure_type_name"
            value={measure_type_name}
            margin="15px 7px 0px 0px"
            onSelect={(item) =>
              onChange({ measure_type_id: item.measure_type_id, measure_type_name: item.measure_type_name, measure_unit_id: item.measure_unit_id })
            }
          />
          {measure_unit_id === 1 && <InputNumber
            label="Benchmark"
            margin="15px 0px 0px 7px"
            value={benchmark_formatted}
            onChange={(item) => onChange({ benchmark: item.value, benchmark_formatted: item.formatted_value })}
          />}
          {measure_unit_id === 2 && <InputCurrency
            label="Benchmark"
            margin="15px 0px 0px 7px"
            value={benchmark_formatted}
            onChange={(item) => onChange({ benchmark: item.float_value * 1000000, benchmark_formatted: item.formatted_value })}
          />}
          {measure_unit_id === 3 && <InputPercent
            label="Benchmark"
            margin="15px 0px 0px 7px"
            value={benchmark_formatted}
            onChange={(item) => onChange({ benchmark: item.float_value, benchmark_formatted: item.formatted_value })}
          />}
          {measure_unit_id === 4 && <InputNumber
            label="Benchmark"
            margin="15px 0px 0px 7px"
            value={benchmark_formatted}
            onChange={(item) => onChange({ benchmark: item.value, benchmark_formatted: item.formatted_value })}
          />}
        </div>
        <div className={cn.buttonContainer}>
          <div className={cn.flex} />
          <Button
            button_size="small"
            width="100px"
            margin="10px 0px 0px 0px"
            type="submit"
            disabled={!campaign_group_name}
          >
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
};

ManageCampaignGroups.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};
