import { setCampaignRepresentatives } from 'ducks/actions/campaign-representatives';
import {
  setAdServerCampaignIdentifiersList,
  setAdServersList,
  setAttributionTypesList,
  setBudgetTypesList,
  setCampaignRolesList,
  setCategoriesList,
  setChangeOrderStatesList,
  setCitiesList,
  setCountriesList,
  setCreativeAddonsList,
  setCreativeExtrasList,
  setCreativeMessagesList,
  setCreativeSelectionsList,
  setCreativeTypesList,
  setCreativeVideoEventsList,
  setFeatureDefinitionsList,
  setFrequencyCapIntervalsList,
  setFrequencyIntervalsList,
  setIdentityGraphsList,
  setIdentityTypesList,
  setIntervalsList,
  setInventorySourcesList,
  setLineItemTypesList,
  setMeasureTypesList,
  setMeasureUnitsList,
  setMetroAreasList,
  setOperatingSystemsList,
  setPixelTypesList,
  setPlatformsList,
  setPostalCodesList,
  setRegionsList,
  setRevenueTypesList,
  setStatesList,
  setStrategyBudgetTypesList,
  setTagOperatorsList,
  setTagTypesList,
  setTeamRolesList,
  setThirdPartyReportCadencesList,
  setThirdPartyReportDeliveriesList,
  setThirdPartyReportSourcesList,
  setVendorFeesList,
} from 'ducks/actions/lists';
import { setParameterGroups, setParameters, setParameterTypes } from 'ducks/actions/parameters';
import { setReportTypeParameterGroups, setReportTypes } from 'ducks/actions/report-parameters';
import { updateSettings } from 'ducks/actions/settings';
import { setTeamTemplates } from 'ducks/actions/teams';
import { normalizeCampaignRepresentatives } from 'ducks/normalizers/campaign-representatives';
import { normalizeFeatureDefinitions } from 'ducks/normalizers/feature-definitions';
import { normalizeFrequencyCapIntervals } from 'ducks/normalizers/frequency-cap-intervals';
import { normalizeIdentityGraphs } from 'ducks/normalizers/identity-graphs';
import { normalizeIdentityTypes } from 'ducks/normalizers/identity-types';
import { normalizeMeasureTypes } from 'ducks/normalizers/measure-types';
import { normalizeMeasureUnits } from 'ducks/normalizers/measure-units';
import { normalizeParameterGroups, normalizeParameters, normalizeParameterTypes } from 'ducks/normalizers/parameters';
import { normalizeReportTypeParameterGroups, normalizeReportTypes } from 'ducks/normalizers/report-parameters';
import { normalizeTeamRolesList } from 'ducks/normalizers/teams';
import { Gemini } from 'utils/axios';
import { handleError } from 'utils/errors';
import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';

import { getBidAlgorithms } from './bid-algorithms';
import { getOutputs } from './outputs';
import { getReportEntitiesList } from './report-entities-list';
import { getRoles } from './roles';
import { getTitanKpiTypes } from './titan-kpi-types';
import { normalizeCreativeMessages } from 'ducks/normalizers/creative-messages';

export const getLookups = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  try {
    await Promise.all([
      dispatch(getMeasureUnits()),
      dispatch(getMeasureTypes()),
      dispatch(getIntervals()),
      dispatch(getCampaignRepresentatives()),
      dispatch(getCategories()),
      dispatch(getInventorySources()),
      dispatch(getFeeVendors()),
      dispatch(getRevenueTypes()),
      dispatch(getBudgetTypes()),
      dispatch(getFeatureDefinitions()),
      dispatch(getStates()),
      dispatch(getCountries()),
      dispatch(getMetroAreas()),
      dispatch(getRegions()),
      dispatch(getCities()),
      dispatch(getPostalCodes()),
      dispatch(getOperatingSystems()),
      dispatch(getTeamTemplates()),
      dispatch(getTeamRoles()),
      dispatch(getAttributionTypes()),
      dispatch(getFrequencyCapIntervals()),
      dispatch(getLineItemTypes()),
      dispatch(getCampaignRoles()),
      dispatch(getPlatforms()),
      dispatch(getFrequencyIntervals()),
      dispatch(getCreativeTypes()),
      dispatch(getAdServers()),
      dispatch(getCreativeAddOns()),
      dispatch(getAdServerCampaignIdentifiers()),
      dispatch(getCreativeExtras()),
      dispatch(getStrategyBudgets()),
      dispatch(getChangeOrderStates()),
      dispatch(getBidAlgorithms()),
      dispatch(getCreativeSelections()),
      dispatch(getVideoEvents()),
      dispatch(getReportTypeParameterGroups()),
      dispatch(getTagTypes()),
      dispatch(getTagOperators()),
      dispatch(getRoles()),
      dispatch(getOutputs()),
      dispatch(getPixelTypesList()),
      dispatch(getIdentityGraphsList()),
      dispatch(getIdentityTypesList()),
      dispatch(getTitanKpiTypes()),
      dispatch(getCreativeMessagesList()),
    ]);
    dispatch(updateSettings({ loaded_lists: true }));
  } catch (err) {

    handleError(err);
  }
};

export const getTagOperators = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/tag_operators`);
  const tag_operators = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setTagOperatorsList(tag_operators));
  return tag_operators;
};

export const getTagTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/tag_types`);
  const tag_types = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setTagTypesList(tag_types));
  return tag_types;
};

export const getCreativeExtras = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/creative_extras`);
  const creative_extras = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'creative_extra_name', 'asc'));
  await dispatch(setCreativeExtrasList(creative_extras));
  return creative_extras;
};

export const getAdServerCampaignIdentifiers = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/ad_server_campaign_identifiers`);
  const ad_servers = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setAdServerCampaignIdentifiersList(ad_servers));
  return ad_servers;
};

export const getCreativeAddOns = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/add_ons/list`);
  const ad_servers = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'add_on_name', 'asc'));
  await dispatch(setCreativeAddonsList(ad_servers));
  return ad_servers;
};

export const getAdServers = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/ad_servers/list`);
  const ad_servers = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'ad_server_name', 'asc'));
  await dispatch(setAdServersList(ad_servers));
  return ad_servers;
};

export const getCreativeTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/creative_types`);
  const creative_types = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setCreativeTypesList(creative_types));
  return creative_types;
};

export const getMeasureUnits = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/measure_unit/list`);
  const measure_units = normalizeMeasureUnits(res.data);
  await dispatch(setMeasureUnitsList(measure_units));
  return measure_units;
};

export const getMeasureTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/measure_types/list`);
  const measure_types = await normalizeMeasureTypes(res.data);
  await dispatch(setMeasureTypesList(measure_types));
  return measure_types;
};

export const getIntervals = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/interval/list`);
  const intervals = uniqueIdentifiers(res.data);
  await dispatch(setIntervalsList(intervals));
  return intervals;
};

export const getCampaignRepresentatives = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/person_roles/1`);
  const representatives = normalizeCampaignRepresentatives(res.data);
  dispatch(setCampaignRepresentatives(representatives));
  return representatives;
};

export const getThirdPartyReportSources = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/third_party_report_sources');
  const third_party_report_sources = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setThirdPartyReportSourcesList(third_party_report_sources));
  return third_party_report_sources;
};

export const getThirdPartyReportCadences = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/third_party_report_cadences');
  const third_party_report_cadences = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setThirdPartyReportCadencesList(third_party_report_cadences));
  return third_party_report_cadences;
};

export const getThirdPartyReportDeliveries = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/third_party_report_deliveries');
  const third_party_report_deliveries = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setThirdPartyReportDeliveriesList(third_party_report_deliveries));
  return third_party_report_deliveries;
};

export const getCampaignRoles = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/campaign_roles');
  const campaign_roles = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setCampaignRolesList(campaign_roles));
  return campaign_roles;
};

export const getTeamRoles = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));

  const res = await Gemini.get('/campaigns/person_roles');
  const team_roles_list = normalizeTeamRolesList(res.data);
  dispatch(setTeamRolesList(team_roles_list));
  return team_roles_list;
};

export const getTeamTemplates = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/campaigns/team_templates');
  const team_templates = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'template_name', 'asc'));
  dispatch(setTeamTemplates(team_templates));
  return team_templates;
};

export const getCategories = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/categories/list`);
  const categories = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'category_name', 'asc'));
  await dispatch(setCategoriesList(categories));
  return categories;
};

export const getInventorySources = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/inventory_sources');
  const inventory_sources = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setInventorySourcesList(inventory_sources));
  return inventory_sources;
};

export const getFeeVendors = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/fee_vendors');
  const fee_vendors = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setVendorFeesList(fee_vendors));
};

export const getAttributionTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/attribution_type/list');
  const attribution_types = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setAttributionTypesList(attribution_types));
  return attribution_types;
};

export const getRevenueTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/revenue_types');
  const revenue_types = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  dispatch(setRevenueTypesList(revenue_types));
  return revenue_types;
};

export const getVideoEvents = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/video_event/list`);
  const video_events = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'video_event_id', 'asc'));
  await dispatch(setCreativeVideoEventsList(video_events));
  return video_events;
};

export const getBudgetTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/budget_type/list`);
  const budget_types = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setBudgetTypesList(budget_types));
  return budget_types;
};

export const getFeatureDefinitions = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/features/feature_definitions`);
  const feature_definitions = normalizeFeatureDefinitions(res.data);
  await dispatch(setFeatureDefinitionsList(feature_definitions));
  return feature_definitions;
};

export const getStates = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/state/list`);
  const states = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setStatesList(states));
  return states;
};

export const getCountries =
  (filter = '') =>
    async (dispatch) => {
      const res = await Gemini.post(`/countries/list`, {
        size_limit: 50,
        pattern: filter || '',
      });
      const countries = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'description', 'asc'));
      await dispatch(setCountriesList(countries));
      return countries;
    };

export const getMetroAreas =
  (filter = '') =>
    async (dispatch) => {
      const res = await Gemini.post(`/metro_areas/list`, {
        size_limit: 50,
        pattern: filter || '',
      });
      const metro_areas = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'description', 'asc'));
      await dispatch(setMetroAreasList(metro_areas));
      return metro_areas;
    };

export const getRegions = (filter) => async (dispatch) => {
  const res = await Gemini.post(`/regions/list`, {
    size_limit: 50,
    pattern: filter || '',
  });
  const regions = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'description', 'asc'));
  await dispatch(setRegionsList(regions));
  return regions;
};

export const getCities = (filter) => async (dispatch) => {
  const res = await Gemini.post(`/cities/list`, {
    size_limit: 50,
    pattern: filter || '',
  });
  const cities = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'city_name', 'asc'));
  await dispatch(setCitiesList(cities));
  return cities;
};

export const getPostalCodes = (filter) => async (dispatch) => {
  const res = await Gemini.post(`/postal_codes/list`, {
    size_limit: 50,
    pattern: filter || '',
  });
  const postal_codes = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'description', 'asc'));
  await dispatch(setPostalCodesList(postal_codes));
  return postal_codes;
};

export const getOperatingSystems = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/operating_systems');
  const operating_systems = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setOperatingSystemsList(operating_systems));
  return operating_systems;
};

export const getFrequencyCapIntervals = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/frequency_cap_intervals/list`);
  const frequency_cap_intervals = normalizeFrequencyCapIntervals(res.data);
  await dispatch(setFrequencyCapIntervalsList(frequency_cap_intervals));
  return frequency_cap_intervals;
};

export const getLineItemTypes = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/line_item_types');
  const line_item_types = uniqueIdentifiers(res.data, 'id').sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setLineItemTypesList(line_item_types));
  return line_item_types;
};

export const getPlatforms = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/platform_types');
  const platforms = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setPlatformsList(platforms));
  return platforms;
};

export const getStrategyBudgets = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/strategy_budget_types');
  const budget_lists = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'name', 'asc'));
  await dispatch(setStrategyBudgetTypesList(budget_lists));
  return budget_lists;
};

export const getCreativeSelections = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/creative_selections/list');
  const creative_selections = uniqueIdentifiers(res.data).sort((a, b) =>
    checkSort(a, b, 'creative_selection_name', 'asc'),
  );
  await dispatch(setCreativeSelectionsList(creative_selections));
  return creative_selections;
};

export const getFrequencyIntervals = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/frequency_cap_intervals/list');
  const frequency_intervals = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'interval_id', 'asc'));
  await dispatch(setFrequencyIntervalsList(frequency_intervals));
  return frequency_intervals;
};

export const getChangeOrderStates = () => async (dispatch) => {
  dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get('/lookups/workflow_state/list');
  const change_order_states = uniqueIdentifiers(res.data);
  await dispatch(setChangeOrderStatesList(change_order_states));
  return change_order_states;
};

export const getParameters = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/parameters`);
  const parameters = normalizeParameters(res.data);
  await dispatch(setParameters(parameters));
  return parameters;
};

export const getParameterTypes = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/parameter_types`);
  const parameter_types = normalizeParameterTypes(res.data);
  await dispatch(setParameterTypes(parameter_types));
  return res.data;
};

export const getParameterGroups = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/parameter_groups`);
  const parameter_groups = normalizeParameterGroups(res.data);
  await dispatch(setParameterGroups(parameter_groups));
  return res.data;
};

export const getReportTypes = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/report_types`);
  const report_types = normalizeReportTypes(res.data);
  dispatch(setReportTypes(report_types));
  return report_types;
};

export const getReportTypeParameterGroups = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/reports/report_type_parameter_groups`);
  const report_type_parameter_groups = normalizeReportTypeParameterGroups(res.data);
  await dispatch(setReportTypeParameterGroups(report_type_parameter_groups));
  return report_type_parameter_groups;
};

export const getPixelTypesList = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/pixel_types`);
  const pixel_types = uniqueIdentifiers(res.data);
  await dispatch(setPixelTypesList(pixel_types));
  return pixel_types;
};

export const getIdentityGraphsList = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/identity_graph`);
  const identity_graphs = normalizeIdentityGraphs(res.data);
  await dispatch(setIdentityGraphsList(identity_graphs));
  return identity_graphs;
};

export const getIdentityTypesList = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/lookups/identity_type`);
  const identity_types = normalizeIdentityTypes(res.data);
  await dispatch(setIdentityTypesList(identity_types));
  return identity_types;
};

export const getCreativeMessagesList = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/creatives/creative_messages`);
  const creative_messages = normalizeCreativeMessages(res.data)
  await dispatch(setCreativeMessagesList(creative_messages))
  return creative_messages;
}