import React from 'react';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getTextWidth } from 'utils/helpers';
import { mergeQuery, replaceQuery } from 'utils/queries';

import cn from './megaMenu.module.scss';

export const MegaMenu = ({
  labels,
  values,
  page,
  match,
  width,
  select_width = 'max-content',
  color = '#e855b6',
  iconEnable = true,
  height = 30,
  position = 'bottom',
  scroll_height = 200,
  transparent = false,
  left,
  right,
  onKeyDown,
}) => {
  const location = useLocation();
  const { change_order_strategies, change_orders } = useSelector(
    (state) => ({
      change_order_strategies: state.query_parameters.change_order_strategies,
      change_orders: state.change_orders,
    }),
    shallowEqual,
  );
  const queries = change_order_strategies || {
    compress: true,
    strategies_active: true,
    strategies_reference: true,
    change_orders_cancelled: change_orders.workflow_state_id === 6,
    weights: false,
  };
  const linkMegaMenu = (item) => {
    switch (page) {
      case 'Advertiser':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.advertiser_name)])}
              to={`/advertisers/${item.advertiser_id}/overview`}
            >
              {item.advertiser_name}
            </Link>
          </div>
        );

      case 'Campaign':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.campaign_name)])}
              to={`/advertisers/${item.advertiser_id}/campaigns/${item.campaign_id}/flights`}
            >
              {item.campaign_name}
            </Link>
          </div>
        );
      case 'Flight':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.flight_name)])}
              to={`/advertisers/${item.advertiser_id}/campaigns/${item.campaign_id}/flights/${item.flight_id}/line-items?highlights=true`}
            >
              {item.flight_name}
            </Link>
          </div>
        );
      case 'ChangeOrder':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.change_order_name)])}
              to={`/advertisers/${item.advertiser_id}/campaigns/${item.campaign_id}/flights/${item.flight_id}/change-orders/${item.change_order_id}/configuration${query}`}
            >
              {item.change_order_name}
            </Link>
          </div>
        );
      case 'Filter':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.filter_name)])}
              to={`/advertisers/${item.advertiser_id}/filters/${item.filter_id}/features?feature_definition_id=33`}
            >
              {item.filter_name}
            </Link>
          </div>
        );
      case 'Creative':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.creative_name)])}
              to={`/advertisers/${match.params.advertiser_id}/campaigns/${match.params.campaign_id}/flights/${match.params.flight_id}/creatives/${item.creative_id}/configuration`}
            >
              {item.creative_name}
            </Link>
          </div>
        );
      case 'Tag':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.tag_name)])}
              to={`/advertisers/${item.advertiser_id}/tags/${item.tag_id}/configuration`}
            >
              {item.tag_name}
            </Link>
          </div>
        );
      case 'LineItem':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.line_item_name)])}
              to={`/advertisers/${item.advertiser_id}/campaigns/${item.campaign_id}/flights/${item.flight_id}/line-items/${item.line_item_id}/configuration`}
            >
              {item.line_item_name}
            </Link>
          </div>
        );
      case 'Dashboards':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.name)])}
              state_id={item.is_active ? 1 : 3}
              to={`${item.pathname}${selectItem(item)}`}
            >
              {item.name}
            </Link>
          </div>
        );
      case 'Dashboard':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.dashboard_name)])}
              state_id={item.is_active ? 1 : 3}
              to={`/dashboards/${item.dashboard_id}/dashboard`}
            >
              {item.dashboard_name}
            </Link>
          </div>
        );
      case 'Reports':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.name)])}
              to={`${item.pathname}${selectItem(item)}`}
            >
              {item.name}
            </Link>
          </div>
        );
      case 'Report':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.report_name)])}
              to={`/reports/${item.report_id}/report`}
            >
              {item.report_name}
            </Link>
          </div>
        );
      case 'Analytics':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.name)])}
              to={`${item.pathname}${selectItem(item)}`}
            >
              {item.name}
            </Link>
          </div>
        );
      case 'Parameters':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              className={classNames(cn.link, cn[handleState(item.name)])}
              to={`${item.pathname}`}
              key={item.state_id}
            >
              {item.name}
            </Link>
          </div>
        );
      case 'Admin':
        return (
          <div className={classNames(cn.option)} key={item.uuid}>
            <Link
              key={item.state_id}
              className={classNames(cn.link, cn[handleState(item.name)])}
              to={`${item.pathname}${selectItem(item)}`}
            >
              {item.name}
            </Link>
          </div>
        );
      default:
        return <p key={1}>nothing to select</p>;
    }
  };
  const handleState = (state_name) => {
    if (labels === state_name) {
      return 'pink';
    }
  };

  const selectItem = (item) => {
    const { search } = location;
    const queries = mergeQuery(search, item.query);
    return queries;
  };
  const style = {
    maxHeight: scroll_height,
    minWidth: select_width,
    width,
    marginTop: 0,
    left,
    right,
    bottom: position === 'top' && height ? height - 1 : undefined,
  };
  if (transparent) {
    style.marginTop = 0;
    style.left = left === 0 ? left - 1 : left;
    style.right = right === 0 ? right - 1 : right;
  }

  const query = replaceQuery(queries);
  const widths = labels ? width : getTextWidth(page);
  return (
    <>
      <div className={cn.wrapper}>
        <div className={cn.dropdown}>
          <div onClick={onKeyDown} className={cn.flex} style={{ height: 47 }}>
            <p
              className={cn.base}
              style={{
                minWidth: widths,
                color: color,
                textAlign: 'left',
                cursor: 'pointer',
                caretColor: 'transparent',
              }}
            >
              {labels || page}
            </p>
            {iconEnable && <FontAwesomeIcon icon={faCaretUp} className={classNames(cn.icon)} />}
          </div>
          <div className={cn.select} style={style}>
            {values.map(linkMegaMenu)}
          </div>
        </div>
      </div>
    </>
  );
};
