export const negativeNumber = (min = -1, max = -10000) => Math.round(Math.random() * (max - min) + min);

export const padNumber = (number, digits) => String(number).padStart(digits, '0');

export const formatPhone = (phone) => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intl_code = match[1] ? '+1 ' : '';
    return [intl_code, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return '';
};

export const calculateCpm = (budget, impressions) => {
  if (budget === null || impressions === null) {
    return `$0.00`;
  }

  const cpm_raw = (budget / impressions) * 1000;

  if (Number.isNaN(cpm_raw)) {
    return `$0.00`;
  }

  return formatNumber(cpm_raw, { format: 'currency-comma' });
};

const numberCheck = (number) => {
  if (number === undefined || Number.isNaN(number) || !Number.isFinite(number)) {
    return true;
  }
  return false;
};

export const formatBudgetSpend = (num, option = {}) => {
  const options = {
    append: 0,
    round: false,
    ...option,
  };
  const { append, round } = options;
  if (numberCheck(num)) {
    return append;
  }
  if (num === 0) {
    return 0;
  }
  if (round) {
    return Math.round(num / 1000000);
  }
  return num / 1000000;
};

export function currencyMask(num, option = {}) {
  const options = {
    decimals: false,
    append: 0,
    ...option,
  };
  const { append, decimals } = options;
  if (numberCheck(num)) {
    return {
      masked: '',
      raw: append,
    };
  }

  const number = Number(num);
  let currency = String(Math.round(Math.abs(number)));
  let currency_formatted = `$${currency.replace(/(\d)(?=(\d{3})+$)/g, '$1,')}`;

  if (decimals) {
    currency = Math.abs(number).toFixed(2);
    const currency_array = String(currency).split('.');
    currency_formatted = `$${currency_array[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')}.${currency_array[1]}`;
  }

  if (Math.sign(number) === -1) {
    return {
      masked: `-${currency_formatted}`,
      raw: number,
    };
  }

  return {
    masked: currency_formatted,
    raw: number,
  };
}

export const percentMask = (num) => {
  // Remove all characters except for negative
  const number = String(num).replace(/[^0-9-.]/g, '');
  return {
    masked: `${Number(number)}%`,
    raw: number / 100,
  };
};

export function numberMask(num, option = {}) {
  const options = {
    append: null,
    ...option,
  };
  const { append } = options;
  if (numberCheck(num)) {
    return {
      masked: '',
      raw: append,
    };
  }

  // Remove all characters except for negative
  const number = String(num).replace(/[^0-9-]/g, '');

  // Returns comma number
  const number_formatted = String(number).replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  return {
    masked: number_formatted,
    raw: Number(number),
  };
}

export function abbreviateNumber(num, option = {}) {
  const options = {
    decimals: 1,
    ...option,
  };
  const { decimals } = options;
  const fix = !decimals || decimals < 0 ? 0 : decimals; // number of decimal places to show
  const b = num.toPrecision(2).split('e'); // get power
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
  const c = k < 1 ? num.toFixed(fix) : (num / 10 ** (k * 3)).toFixed(fix); // divide by power
  const d = c < 0 ? Number(c) : Math.abs(c); // enforce -0 is 0
  const f = d < 10 ? d : Math.round(d); // *** NEW LOGIC ***
  const e = f + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

export function formatRates(number, option = {}) {
  const options = {
    format: '',
    type: '',
    decimals: 1,
    append: 'N/A',
    ...option,
  };

  const { format } = options;
  const number_round = Math.round(number);
  const regular_expression = new RegExp(/\B(?=(\d{3})+(?!\d))/g);

  switch (format) {
    case 'rate':
      if (number < 1) {
        if (Number.isInteger(number)) {
          return `${number}x`;
        }
        if (Number.isInteger(number * 10)) {
          return `${number.toFixed(1)}x`;
        }
        if (Number.isInteger(number * 100)) {
          return `${number.toFixed(2)}x`;
        }
        return `${number.toFixed(3)}x`;
      }
      if (number < 10) {
        if (Number.isInteger(number)) {
          return `${number}x`;
        }
        if (Number.isInteger(number * 10)) {
          return `${number.toFixed(1)}x`;
        }
        return `${number.toFixed(2)}x`;
      }
      if (number < 100) {
        if (Number.isInteger(number)) {
          return `${number}x`;
        }
        return `${number.toFixed(1)}x`;
      }
      return `${abbreviateNumber(number_round, options)}x`;
    case 'rate-rounded':
      return `${number_round}x`;
    case 'rate-abbreviated':
      return `${abbreviateNumber(number_round, options)}x`;
    case 'rate-comma':
      return `${number.toString().replace(regular_expression, ',')}x`;
    default:
      return number;
  }
}

export const setNumberRange = (num, min = -30, max = 30) => {
  if (num === null) {
    return 0;
  }
  if (num >= max) {
    return max;
  }
  if (num <= min) {
    return min;
  }
  return num;
};

export const formatMicroUsd = (num, option = {}) => {
  const options = {
    append: null,
    ...option,
  };
  const { append } = options;
  if (numberCheck(num)) {
    return append;
  }
  return Number((num / 1000).toFixed(2));
};
export const numberArrayCheck = (array) => {
  if (!array) {
    return false;
  }
  return array.some((item) => !!item);
};

export const formatNumber = (num, option = {}) => {
  const options = {
    format: '',
    type: '',
    decimals: 1,
    transform: null,
    append: 'N/A',
    ...option,
  };

  const { append, format, transform } = options;
  const number = Number(num);
  if (numberCheck(number) || num === transform) {
    return append;
  }

  switch (true) {
    case String(format).includes('currency'):
      return formatCurrencies(number, options);
    case String(format).includes('percentage'):
      return formatPercentages(number, options);
    case String(format).includes('number'):
      return formatNumbers(number, options);
    case String(format).includes('rate'):
      return formatRates(number, options);
    default:
      return number;
  }
};

export function formatCurrencies(number, option = {}) {
  const options = {
    format: '',
    type: '',
    decimals: 1,
    append: 'N/A',
    ...option,
  };

  const { format } = options;
  // const regular_expression = new RegExp(/^[-]?[$]\d{1,3}(?:,?\d{3})*\.\d{2}$/g);
  const regular_expression = new RegExp(/(\d)(?=(\d{3})+(?!\d))/g);
  const number_abs = Math.abs(number);
  const number_rounded = Math.round(number_abs);
  const number_sign = Math.sign(number) === -1;

  switch (format) {
    case 'currency':
      if (number_sign) {
        return `-$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
      }
      return `$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
    case 'currency-unlimited':
      if (number_sign) {
        return `-$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
      }
      return `$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
    case 'currency-dynamic':
      if (number_abs > 0 && number_abs < 100) {
        if (number_sign) {
          return `-$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
        }
        return `$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
      }
      if (number_abs < 1000) {
        if (number_sign) {
          return `-$${String(Math.round(number_abs)).replace(regular_expression, '$1,')}`;
        }
        return `$${String(Math.round(number)).replace(regular_expression, '$1,')}`;
      }
      if (number_sign) {
        return `-$${abbreviateNumber(Math.round(number_abs), options)}`;
      }
      return `$${abbreviateNumber(number, options)}`;
    case 'currency-comma':
      return `$${String(number.toFixed(2)).replace(regular_expression, '$1,')}`;
    case 'currency-abbreviated':
      if (number_abs > 0 && number_abs < 100) {
        if (number_sign) {
          return `-$${String(number_abs.toFixed(2)).replace(regular_expression, '$1,')}`;
        }
        return `$${number.toFixed(2).toString().replace(regular_expression, '$1,')}`;
      }
      if (number_abs >= 10 && number_abs < 1000) {
        if (number_sign) {
          return `-$${String(number_rounded).replace(regular_expression, '$1,')}`;
        }
        return `$${String(Math.round(number)).replace(regular_expression, '$1,')}`;
      }
      if (number_sign) {
        return `-$${abbreviateNumber(number_rounded, options)}`;
      }
      return `$${abbreviateNumber(number, options)}`;
    default:
      return number;
  }
}

export const formatPercentages = (number, option = {}) => {
  const options = {
    format: '',
    type: '',
    decimals: 1,
    append: 'N/A',
    ...option,
  };

  const { format, decimals } = options;
  const number_abs = Math.abs(number);
  const number_integer = Number.isInteger(number);
  const number_integer_10x = Number.isInteger(number * 10);
  const number_integer_100x = Number.isInteger(number * 100);

  switch (format) {
    case 'percentage':
      if (number_integer) {
        return `${number}%`;
      }
      if (number_integer_10x) {
        return `${number.toFixed(1)}%`;
      }
      return `${number.toFixed(2)}%`;
    case 'percentage-multiplied':
      return number * 100;
    case 'percentage-formatted':
      return `${number.toFixed(decimals)}%`;
    case 'percentage-rounded':
      return `${Math.round(number * 100)}%`;
    case 'percentage-performance':
      return `${Math.round(number)}%`;
    case 'percentage-abbreviated':
      return `${abbreviateNumber(number * 100, options)}%`;
    case 'percentage-fixed':
      return `${(number * 100).toFixed(decimals)}%`;
    case 'percentage-strategy':
      if (number_integer) {
        return `${number / 100}%`;
      }
      if (number_integer_10x) {
        return `${Number((number / 100).toFixed(1))}%`;
      }
      if (number_integer_100x) {
        return `${Number((number / 100).toFixed(2))}%`;
      }
      return `${Number((number / 100).toFixed(3))}%`;
    case 'percentage-range':
      if (number_abs * 100 > 1) {
        return `${abbreviateNumber(number)}%`;
      }
      if (number === 0) {
        return `0%`;
      }
      return `${abbreviateNumber(number, { decimals: 2 })}%`;
    case 'percentage-incrementality':
      if (number_abs > 1) {
        return `${abbreviateNumber(number)}%`;
      }
      if (number === 0) {
        return `0%`;
      }
      return `${abbreviateNumber(number, { decimals: 4 })}%`;
    case 'percentage-decimals':
      if (number < 1) {
        if (Number.isInteger(number)) {
          return `${number}%`;
        }
        if (Number.isInteger(number * 10)) {
          return `${number.toFixed(1)}%`;
        }
        if (Number.isInteger(number * 100)) {
          return `${number.toFixed(2)}%`;
        }
        return `${number.toFixed(3)}%`;
      }
      if (number < 10) {
        if (Number.isInteger(number)) {
          return `${number}%`;
        }
        if (Number.isInteger(number * 10)) {
          return `${number.toFixed(1)}%`;
        }
        return `${number.toFixed(2)}%`;
      }
      if (number < 100) {
        if (Number.isInteger(number)) {
          return `${number}%`;
        }
        return `${number.toFixed(1)}%`;
      }
      return `${abbreviateNumber(Math.round(number), options)}%`;
    default:
      return number;
  }
};

export const formatNull = (num, append = 0, check = 'N/A') => {
  if (num === null || num === check) {
    return append;
  }
  return num;
};

export function formatNumbers(number, option = {}) {
  const options = {
    format: '',
    type: '',
    decimals: 1,
    append: 'N/A',
    ...option,
  };

  const { format, decimals } = options;
  const number_abs = Math.abs(number);
  const number_round = Math.round(number);
  const number_integer = Number.isInteger(number);
  const number_integer_10x = Number.isInteger(number * 10);
  const number_integer_100x = Number.isInteger(number * 100);
  const regular_expression = new RegExp(/\B(?=(\d{3})+(?!\d))/g);
  const number_sign = Math.sign(number) === -1;
  switch (format) {
    case 'number':
      if (number_abs >= 0 && number_abs < 100) {
        if (number_integer) {
          return number;
        }
        if (number_integer_10x) {
          return number.toFixed(1);
        }
        return number.toFixed(2);
      }
      if (number_abs >= 10 && number_abs < 1000) {
        return parseInt(number, 10);
      }
      return abbreviateNumber(number, options);
    case 'number-rounded':
      return number_round;
    case 'number-abbreviated':
      return `${abbreviateNumber(number_round, options)}`;
    case 'number-comma':
      return `${String(number_round).replace(regular_expression, ',')}`;
    case 'number-negative':
      if (number_sign) {
        return `(${String(number).replace(regular_expression, ',')})`;
      }
      return `${String(number).replace(regular_expression, ',')}`;
    case 'number-cpm':
      return number / 1000;
    case 'number-strategy':
      if (number_integer) {
        return number / 1000;
      }
      if (number_integer_10x) {
        return Number((number / 1000).toFixed(1));
      }
      if (number_integer_100x) {
        return Number((number / 1000).toFixed(2));
      }
      return Number((number / 1000).toFixed(3));
    case 'number-decimal':
      if (number_integer) {
        return number;
      }
      if (number_integer_10x) {
        return number.toFixed(1);
      }
      if (number_integer_100x) {
        return number.toFixed(2);
      }
      return number.toFixed(decimals);
    default:
      return number;
  }
}
