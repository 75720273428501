import { clearCampaignCumulativePerformance } from 'ducks/actions/campaign-cumulative-performance';
import { setCampaignMetrics } from 'ducks/actions/campaign-metrics';
import { clearCampaignPacingPerformance } from 'ducks/actions/campaign-pacing-performance';
import { setCampaign, setCampaigns } from 'ducks/actions/campaigns';
import { setKpiGroup } from 'ducks/actions/kpi-groups';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeCampaignMetrics } from 'ducks/normalizers/campaign-metrics';
import { normalizeCampaign, normalizeCampaigns } from 'ducks/normalizers/campaigns';
import { Gemini } from 'utils/axios';
import { subtractFromDate } from 'utils/dates';
import { replaceQuery } from 'utils/queries';
import { checkSort } from 'utils/string';

import { getAdvertiser, getAdvertisers } from './advertisers';
import { getCampaignCumulativePerformance } from './campaign-cumulative-performance';
import { getCampaignPacingPerformance } from './campaign-pacing-performance';
import { getCampaignCreatives } from './creatives';
import { getCampaignFlights } from './flights';
import { getCampaignFrequencyCaps } from './frequency-caps';
import { getCampaignKpiGroups } from './kpi-groups';
import { getCampaignKpiIntervals } from './kpi-intervals';
import { getCampaignExternalLineItems, getCampaignLineItems, getCampaignPacingLineItems } from './line-items';
import { getAdvertiserTags } from './tags';
import { getCampaignTeam } from './teams';
import { getCampaignThirdPartyReports } from './third-party-reports';

export const getCampaignPage = (params) => async (dispatch) => {
  const { advertiser_id, campaign_id } = params;

  const [kpi_groups] = await Promise.all([
    dispatch(getCampaignKpiGroups(campaign_id)),
    dispatch(getAdvertisers()),
    dispatch(getAdvertiser(advertiser_id)),
    dispatch(getAdvertiserCampaigns(advertiser_id)),
    dispatch(getCampaign(campaign_id)),
    dispatch(getCampaignMetrics(campaign_id)),
    dispatch(getCampaignFrequencyCaps(campaign_id)),
  ]);

  if (kpi_groups && kpi_groups.length > 0) {
    await dispatch(setKpiGroup(kpi_groups[0]));
  }

  return kpi_groups;
};

export const getCampaignOverviewPage =
  (params = {}, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { campaign_id, kpi_group_id } = params;
    const query = {
      campaign_id,
      kpi_group_id,
      start_date_utc: subtractFromDate(new Date(), { decrement: 31 }),
      finish_date_utc: subtractFromDate(new Date(), { decrement: 1 }),
      ...queries,
    };

    await dispatch(clearCampaignCumulativePerformance());
    await dispatch(clearCampaignPacingPerformance());
    await dispatch(getCampaignKpiGroups(query.campaign_id));

    const [campaign] = await Promise.all([
      dispatch(getCampaignCumulativePerformance(query)),
      dispatch(getCampaignPacingPerformance(query)),
    ]);

    await dispatch(updateSettings({ loading: false }));
    return campaign;
  };

export const getCampaignPacingPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { campaign_id } = params;

  const [campaign] = await Promise.all([
    dispatch(getCampaignLineItems(campaign_id)),
    dispatch(getCampaignPacingLineItems(campaign_id)),
  ]);

  await dispatch(updateSettings({ loading: false }));
  return campaign;
};

export const getCampaignKpiGroupPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { campaign_id, advertiser_id } = params;

  const [kpi_groups] = await Promise.all([
    dispatch(getCampaignKpiGroups(campaign_id)),
    dispatch(getCampaignKpiIntervals(campaign_id)),
    dispatch(getAdvertiserTags(advertiser_id)),
  ]);

  if (kpi_groups && kpi_groups.length > 0) {
    await dispatch(setKpiGroup(kpi_groups[0]));
  }

  await dispatch(updateSettings({ loading: false }));
  return kpi_groups;
};

export const getCampaignTeamPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { campaign_id } = params;

  const [teams] = await Promise.all([dispatch(getCampaignTeam(campaign_id))]);

  await dispatch(updateSettings({ loading: false }));
  return teams;
};

export const getCampaignThirdPartyReportsPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { campaign_id } = params;

  const [reports] = await Promise.all([dispatch(getCampaignThirdPartyReports(campaign_id))]);

  await dispatch(updateSettings({ loading: false }));
  return reports;
};

export const getCampaignBudgetPage = (params) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const { campaign_id } = params;

  const [campaign] = await Promise.all([
    dispatch(getCampaignLineItems(campaign_id)),
    dispatch(getCampaignExternalLineItems(campaign_id)),
  ]);

  await dispatch(updateSettings({ loading: false }));
  return campaign;
};

export const getCampaignFlightsPage =
  (params = {}, queries = {}) =>
  async (dispatch) => {
    await dispatch(updateSettings({ loading: true }));

    const { campaign_id } = params;

    const query = {
      state_id: null,
      include_state_ids: [1, 2, 3, 4],
      exclude_state_ids: [],
      ...queries,
    };

    const [campaign] = await Promise.all([dispatch(getCampaignFlights(campaign_id, query))]);

    await dispatch(updateSettings({ loading: false }));
    return campaign;
  };

export const getCampaigns = (queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    include_archived: false,
    ...queries,
  };
  const res = await Gemini.post(`/campaigns/list`, query);
  const campaigns = await normalizeCampaigns(res.data);
  await dispatch(setCampaigns(campaigns));
  return res.data;
};

export const getAdvertiserCampaigns = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    include_archived: false,
    ...queries,
  };
  const res = await Gemini.get(`/advertisers/${entity_id}/campaigns${replaceQuery(query)}`);
  const campaigns = await normalizeCampaigns(res.data).sort((a, b) => checkSort(a, b, 'campaign_state_sort', 'desc'));
  await dispatch(setCampaigns(campaigns));
  return campaigns;
};

export const getKpiCampaigns = (queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const query = {
    include_archived: false,
    ...queries,
  };

  const res = await Gemini.post(`/kpi_groups/list`, query);
  const campaigns = normalizeCampaigns(res.data).sort((a, b) => checkSort(a, b, 'advertiser_campaign_name', 'asc'));
  await dispatch(setCampaigns(campaigns));
  return res.data;
};

export const getCampaignMetrics = (entity_id) => async (dispatch, getState) => {
  await dispatch(updateSettings({ loading: true }));
  const { measure_types_list } = getState();
  const res = await Gemini.get(`/campaigns/${entity_id}/metrics`, { status: 204, resolve: {} });
  const metrics = normalizeCampaignMetrics(res.data, measure_types_list);
  await dispatch(setCampaignMetrics(metrics));
  return metrics;
};

export const getMovingAverageKpiCampaigns = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/campaigns/moving_average_reports`);
  const campaigns = normalizeCampaigns(res.data);
  await dispatch(setCampaigns(campaigns));
  return res.data;
};

export const getCampaign = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  if (!entity_id) {
    return;
  }

  const res = await Gemini.get(`/campaigns/${entity_id}`);
  const campaign = normalizeCampaign(res.data);
  await dispatch(setCampaign(campaign));
  return campaign;
};

export const createAdvertiserCampaign = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  // normalizing payload - API needs to be update
  data.advertiser_id = entity_id;

  const res = await Gemini.post(`/campaigns/create`, data);
  return res.data;
};

export const updateCampaign = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.post(`/campaigns/${entity_id}/update`, data);
  return res.data;
};

export const pauseCampaigns = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/pause`);
  return res.data;
};

export const resumeCampaigns = () => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/resume`);
  return res.data;
};

export const activateCampaign = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/activate`);
  return res.data;
};

export const pauseCampaign = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/pause`);
  return res.data;
};

export const unarchiveCampaign = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/unarchive`);
  return res.data;
};

export const deactivateCampaign = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/deactivate`);
  return res.data;
};

export const archiveCampaign = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/campaigns/${entity_id}/archive`);
  return res.data;
};
