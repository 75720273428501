import moment from 'moment';
import { formatDate, offsetTimezoneUTC } from 'utils/dates';
import { formatNull, formatNumber } from 'utils/numbers';

export const normalizeCampaignCumulativePerformance = (performance, kpi_group) => {
  const { p_kpi_measure_unit_id, kpi_type, rows } = performance;

  if (rows.length === 0) {
    return {
      labels: [],
      tooltips: [],
      x: [],
      y1: [],
      y2: [],
      y3: [],
      y4: [],
      y5: [],
      y6: [],
    };
  }
  const { date } = rows[rows.length - 1];
  const diff_window = moment().diff(moment(date), 'days'); // Grabbing the attribution window dynamically from today
  const attribution_window = rows.length - (7 - diff_window); // Attribution window 7 days
  const measure_type = p_kpi_measure_unit_id || 2;
  const format = measure_type === 3 ? 'percentage-multiplied' : '';

  const labels = [
    ` ${kpi_group.p_kpi_measure_type.measure_type_name || ''}`,
    ` Attribution`,
    ` External ${kpi_group.p_kpi_measure_type.measure_type_name || ''}`,
  ];

  const tooltips = [
    kpi_group.p_kpi_measure_type.measure_type_abbreviated,
    kpi_group.p_kpi_measure_type.measure_type_abbreviated,
    kpi_group.p_kpi_measure_type.measure_type_abbreviated,
    'number-abbreviated',
    'number-abbreviated',
    'number-abbreviated',
  ];

  return rows
    .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
    .reduce(
      (acc, next, index) => {
        const discrepancy = next.dsp_impressions - next.external_impressions;
        const discrepancy_sign = Math.sign(discrepancy);

        const x = formatDate(next.date, { format: 'MMM D' });

        const y1_raw = formatNumber(next.internal_p_kpi, { format, append: null });
        const y1 = formatNull(y1_raw, null, 0);

        const y3_raw = formatNumber(next.external_p_kpi, { format, append: null });
        const y3 = formatNull(y3_raw, null, 0);

        const y4_raw = discrepancy_sign === -1 ? next.external_impressions + discrepancy : next.external_impressions;
        const y4 = formatNull(y4_raw, null, 0);

        const y5_raw = discrepancy_sign === 1 ? discrepancy : null;
        const y5 = formatNull(y5_raw, null, 0);

        const y6_raw = discrepancy_sign === -1 ? Math.abs(discrepancy) : null; // only positive numbers can be shown
        const y6 = formatNull(y6_raw, null, 0);

        return {
          attribution_window,
          p_kpi_type_name: kpi_type,
          measure_unit_id: p_kpi_measure_unit_id,
          measure_type_abbreviated: kpi_group.p_kpi_measure_type.measure_type_abbreviated,
          measure_type_format: kpi_group.p_kpi_measure_type.measure_type_format,
          labels,
          tooltips,
          x: [...acc.x, x],
          y1: [...acc.y1, index <= attribution_window ? y1 : null],
          y2: [...acc.y2, index >= attribution_window ? y1 : null],
          y3: [...acc.y3, y3],
          y4: [...acc.y4, y4],
          y5: [...acc.y5, y5],
          y6: [...acc.y6, y6],
        };
      },
      {
        attribution_window: 0,
        measure_unit_id: 2,
        measure_type_abbreviated: 'currency-abbreviated',
        measure_type_format: 'currency',
        labels,
        tooltips,
        x: [],
        y1: [],
        y2: [],
        y3: [],
        y4: [],
        y5: [],
        y6: [],
      },
    );
};
