import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, DropdownCalendar, Input, InputNumber, InputCurrency, InputPercent, Modal } from '@cognitiv/cyprus-ui';
import { clearCampaign } from 'ducks/actions/campaigns';
import { updateModal } from 'ducks/actions/modals';
import { updateSettings, updateSuccess } from 'ducks/actions/settings';
import { createCampaignGroup } from 'ducks/operators/campaign-groups';
import { createAdvertiserCampaign, updateCampaign } from 'ducks/operators/campaigns';
import { campaignValidation } from 'ducks/validations/campaigns';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { handleError } from 'utils/errors';
import { campaignNameOverwrite } from 'utils/helpers';
import { mergeQuery } from 'utils/queries';
import { v4 as uuidv4 } from 'uuid';

import cn from './Modal.module.scss';
import { ManageCampaignGroups } from './ManageCampaignGroups';

const IDENTITY_GRAPHS = {
  1: [1],
  2: [2, 3, 4],
};

const default_state = {
  advertiser_id: null,
  start_date: '',
  finish_date: '',
  campaign_name: '',
  campaign_group_name: '',
  campaign_group_id: null,
  budget_type_id: 1,
  budget_type_name: 'Impressions',
  measure_type_name: 'CPA',
  measure_type_id: 24,
  measure_class_id: 3,
  measure_unit_id: 2,
  revenue_type_name: '',
  benchmark_formatted: '',
  benchmark: null,
  revenue_type_id: null,
  finish_offset_hours: null,
  campaign_name_overwrite: true,
  external_source: true,
  dsp_managed: false,
  valid: false,
  identity_graph_id: 1,
  identity_graph_name: 'Umbra',
  kpi_group_segment_identity_type_id: null,
  kpi_group_segment_identity_type_name: '',
};

export const ManageCampaign = ({ history, location, match }) => {
  const dispatch = useDispatch();

  const {
    campaign,
    campaigns,
    campaign_groups,
    budget_types_list,
    measure_types_list,
    revenue_types_list,
    identity_types_list,
    identity_graphs_list,
    titan_kpi_types,
    modals,
  } = useSelector((state) => ({
    campaign: state.campaign,
    campaigns: state.campaigns,
    campaign_groups: state.campaign_groups,
    budget_types_list: state.budget_types_list,
    measure_types_list: state.measure_types_list,
    revenue_types_list: state.revenue_types_list,
    identity_types_list: state.identity_types_list,
    identity_graphs_list: state.identity_graphs_list,
    titan_kpi_types: state.titan_kpi_types,
    modals: state.modals,
  }));

  const [form, setForm] = useState({ ...default_state });

  useEffect(() => {
    if (campaign.campaign_id) {
      setForm(campaign);
    }
    if (!campaign.campaign_id) {
      setDefaults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);


  const setDefaults = () => {
    const today = moment().date();
    const initial_campaign = { ...default_state };

    if (campaigns.length === 1) {
      initial_campaign.campaign_group_name = campaigns[0].campaign_group_name;
      initial_campaign.campaign_group_id = campaigns[0].campaign_group_id;
    }

    if (today === 1) {
      initial_campaign.start_date_formatted = moment().startOf('day').format('M/D/YYYY');
      initial_campaign.finish_date_formatted = moment().endOf('month').format('M/D/YYYY');
    }
    if (today !== 1) {
      initial_campaign.start_date_formatted = moment().add(1, 'month').startOf('month').format('M/D/YYYY');
      initial_campaign.finish_date_formatted = moment().add(1, 'month').endOf('month').format('M/D/YYYY');
    }

    const { campaign_name } = campaignNameOverwrite(
      initial_campaign.start_date_formatted,
      initial_campaign.finish_date_formatted,
      initial_campaign.campaign_group_name,
    );

    initial_campaign.campaign_name = campaign_name;
    initial_campaign.identity_graphs_list = identity_graphs_list;
    initial_campaign.identity_types_list = identity_types_list;

    setForm(initial_campaign);
  };

  const setCampaignGroup = (item) => {
    if (!item.campaign_group_id) {
      dispatch(updateModal({ manage_campaign_group: true }));
    }
    if (item.campaign_group_id) {
      onChange({
        campaign_group_name: item.campaign_group_name,
        campaign_group_id: item.campaign_group_id,
        campaign_name_overwrite: true,
      });
    }
  };

  const onChange = (item) => {
    setForm((prev) => {
      const next = {
        ...prev,
        ...item,
      };
      if (next.campaign_name_overwrite) {
        const { campaign_name } = campaignNameOverwrite(
          next.start_date_formatted,
          next.finish_date_formatted,
          next.campaign_group_name,
        );
        next.campaign_name = campaign_name;
      }
      return next;
    });
  };

  const closeModal = () => {
    // checking params to validate on campaign page
    if (!match.params.campaign_id) {
      dispatch(clearCampaign());
      setForm({ ...default_state });
    }

    dispatch(updateSettings({ loading: false }));
    dispatch(updateModal({ manage_campaign: false }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { pathname, search } = location;
    const { advertiser_id } = match.params;
    const { campaign_id } = campaign;
    const queries = mergeQuery(search, { update: uuidv4() });

    try {
      const data = {
        start_date: form.start_date_formatted,
        finish_date: form.finish_date_formatted,
        campaign_name: form.campaign_name,
        campaign_group_id: form.campaign_group_id,
        budget_type_id: form.budget_type_id,
        p_kpi_measure_type_id: form.measure_type_id,
        revenue_type_id: form.revenue_type_id,
        external_source: form.external_source,
        dsp_managed: form.dsp_managed,
        finish_offset_hours: form.finish_offset_hours,
        identity_graph_id: form.identity_graph_id,
        kpi_group_segment_identity_type_id: form.kpi_group_segment_identity_type_id,
      };

      if (campaign_id) {
        await dispatch(updateCampaign(campaign_id, data));
        history.push(`${pathname}${queries}`);
      }
      if (!campaign_id) {
        // hidden business logic - creating a default campaign group
        if (!data.campaign_group_id) {
          const kpi_type_id = getTitanKpi(form.measure_type_id);
          const campaign_group_id = await dispatch(createCampaignGroup(advertiser_id, { campaign_group_name: 'Default', kpi_type_id, kpi_benchmark: form.benchmark }));
          data.campaign_group_id = campaign_group_id;
        }
        const entity_id = await dispatch(createAdvertiserCampaign(advertiser_id, data));
        history.push(`/advertisers/${advertiser_id}/campaigns/${entity_id}/flights`);
      }
      dispatch(updateSuccess('campaign has been successfully updated'));
    } catch (err) {
      handleError(err);
    }
    closeModal();
  };

  const { campaign_id } = campaign;
  const {
    start_date_formatted,
    finish_date_formatted,
    benchmark_formatted,
    campaign_name,
    campaign_group_id,
    campaign_group_name,
    budget_type_name,
    measure_type_name,
    revenue_type_name,
    external_source,
    finish_offset_hours_formatted,
    identity_graph_name,
    identity_graph_id,
    measure_unit_id,
    kpi_group_segment_identity_type_name,
  } = form;

  const group_exists = campaign_groups.length !== 0;

  const disabled = campaignValidation(form);

  return (
    <>
      <Modal
        padding={16}
        close_box={46}
        name="manage_campaign"
        show={modals.manage_campaign}
        width={550}
        onClose={closeModal}
        standard={false}
      >
        <h3>{campaign_id ? 'Update Campaign' : 'Create Campaign'}</h3>
        <form onSubmit={onSubmit}>
          <div style={{ display: 'flex' }}>
            <DropdownCalendar
              label="Start Date"
              selected_date={start_date_formatted}
              max_date={finish_date_formatted}
              onSelect={(date) => onChange({ start_date_formatted: date.selected_date, campaign_name_overwrite: true })}
              margin="0px 7px 0px 0px"
              width="calc(50% - 7px)"
              calendar_display={false}
            />
            <DropdownCalendar
              label="Finish Date"
              selected_date={finish_date_formatted}
              min_date={start_date_formatted}
              onSelect={(date) => onChange({ finish_date_formatted: date.selected_date, campaign_name_overwrite: true })}
              margin="0px 0px 0px 7px"
              width="calc(50% - 7px)"
              calendar_display={false}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Input
              label="Campaign Name"
              value={campaign_name}
              margin="15px 0px 0px 0px"
              onChange={(value) => onChange({ campaign_name: value, campaign_name_overwrite: false })}
            />
            {group_exists && !campaign.campaign_id && (
              <Dropdown
                options={[
                  {
                    campaign_group_name: '(Create Campaign Group)',
                    campaign_group_id: null,
                  },
                ]}
                option_key="campaign_group_name"
                value={campaign_group_name}
                label="Campaign Group"
                margin="15px 0px 0px 14px"
                disabled={!!campaign_group_id}
                onSelect={setCampaignGroup}
              />
            )}
            {campaign.campaign_id && (
              <InputNumber
                label="Finish Offset Hours"
                value={finish_offset_hours_formatted}
                margin="15px 0px 0px 14px"
                onChange={(input) =>
                  onChange({
                    finish_offset_hours: input.float_value,
                    finish_offset_hours_formatted: input.formatted_value,
                  })
                }
              />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {!campaign.campaign_id && (
              <Dropdown
                options={budget_types_list}
                option_key="name"
                value={budget_type_name}
                label="Budget Type"
                margin="15px 14px 0px 0px"
                onSelect={(item) => onChange({ budget_type_name: item.name, budget_type_id: item.id })}
              />
            )}
            <Dropdown
              options={revenue_types_list}
              option_key="name"
              value={revenue_type_name}
              label="Revenue Type"
              margin="15px 0px 0px 0px"
              onSelect={(item) => onChange({ revenue_type_name: item.name, revenue_type_id: item.id })}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Dropdown
              options={identity_graphs_list}
              option_key="name"
              value={identity_graph_name}
              disabled={campaign.campaign_id}
              label="Identity Graph"
              margin={campaign.campaign_id ? '15px 0px 0px 0px' : '15px 7px 0px 0px'}
              onSelect={(item) =>
                onChange({
                  identity_graph_id: item.id,
                  identity_graph_name: item.name,
                  kpi_group_segment_identity_type_name: '',
                  kpi_group_segment_identity_type_id: null,
                })
              }
            />
            {!campaign.campaign_id && (
              <Dropdown
                options={identity_types_list.filter((identity_type) =>
                  IDENTITY_GRAPHS[identity_graph_id]?.includes(identity_type.id),
                )}
                option_key="name"
                value={kpi_group_segment_identity_type_name}
                label="KPI Identity Type"
                disabled={campaign.campaign_id}
                margin="15px 0px 0px 7px"
                onSelect={(item) =>
                  onChange({
                    kpi_group_segment_identity_type_id: item.id,
                    kpi_group_segment_identity_type_name: item.name,
                  })
                }
              />
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {!campaign_id && <Dropdown
              label="Primary KPI"
              options={measure_types_list.filter((m) => m.measure_class_id === 3)}
              option_key="measure_type_name"
              value={measure_type_name}
              disabled={campaign.campaign_id}
              margin="15px 7px 0px 0px"
              onSelect={(item) =>
                onChange({ measure_type_id: item.measure_type_id, measure_type_name: item.measure_type_name, measure_unit_id: item.measure_unit_id })
              }
            />}
            {measure_unit_id === 1 && <InputNumber
              label="Benchmark"
              margin="15px 0px 0px 7px"
              value={benchmark_formatted}
              disabled={campaign.campaign_id}
              onChange={(item) => onChange({ benchmark: item.value, benchmark_formatted: item.formatted_value })}
            />}
            {measure_unit_id === 2 && <InputCurrency
              label="Benchmark"
              margin="15px 0px 0px 7px"
              value={benchmark_formatted}
              disabled={campaign.campaign_id}
              onChange={(item) => onChange({ benchmark: item.float_value * 1000000, benchmark_formatted: item.formatted_value })}
            />}
            {measure_unit_id === 3 && <InputPercent
              label="Benchmark"
              margin="15px 0px 0px 7px"
              value={benchmark_formatted}
              disabled={campaign.campaign_id}
              onChange={(item) => onChange({ benchmark: item.float_value, benchmark_formatted: item.formatted_value })}
            />}
            {measure_unit_id === 4 && <InputNumber
              label="Benchmark"
              margin="15px 0px 0px 7px"
              value={benchmark_formatted}
              disabled={campaign.campaign_id}
              onChange={(item) => onChange({ benchmark: item.value, benchmark_formatted: item.formatted_value })}
            />}
          </div>
          <Checkbox
            margin="15px 7px 0px 0px"
            width="max-content"
            checked={external_source}
            onClick={(checked) => onChange({ external_source: checked })}
          >
            External Reporting
          </Checkbox>
          <div className={cn.buttonContainer}>
            <div className={cn.flex} />
            <Button button_size="small" width="100px" margin="10px 0px 0px 0px" type="submit" disabled={!disabled}>
              {campaign_id ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </Modal>
      <ManageCampaignGroups history={history} location={location} match={match} onCreate={setCampaignGroup} />
    </>
  );
};

ManageCampaign.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};


export const getTitanKpi = (measure_type_id) => {
  switch (measure_type_id) {
    case 24: // CPA
      return 2
    case 22: // ROAS
      return 3
    case 18: // CTR
      return 8
    default:
      return null
  }
}