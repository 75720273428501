import { formatPhone } from 'utils/numbers';
import { checkSort } from 'utils/string';
import { v4 as uuidv4 } from 'uuid';

export const normalizeTeam = (data = []) =>
  data.map((d) => ({
    ...d,
    phone_number_formatted: formatPhone(d.phone_number),
    uuid: uuidv4(),
  }));

export const normalizeTeamRolesList = (data = []) =>
  data
    .map((d) => ({ ...d, phone_number_formatted: formatPhone(d.phone_number), uuid: uuidv4() }))
    .sort((a, b) => checkSort(a, b, 'campaign_role_name', 'asc'));
