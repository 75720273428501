import { CLEAR_TEAM_ROLES_LIST, SET_TEAM_ROLES_LIST } from '../types';

export const teamRolesListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_TEAM_ROLES_LIST:
      return action.payload;
    case CLEAR_TEAM_ROLES_LIST:
      return [];
    default:
      return state;
  }
};
