import { setCampaignGroups } from 'ducks/actions/campaign-groups';
import { updateSettings } from 'ducks/actions/settings';
import { Gemini } from 'utils/axios';
import { uniqueIdentifiers } from 'utils/helpers';
import { checkSort } from 'utils/string';

export const getAdvertiserCampaignGroups = (entity_id, queries) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const query = {
    include_archived: false,
    ...queries,
  };

  const res = await Gemini.post(`/advertisers/${entity_id}/campaign_groups/list`, query);
  const campaign_groups = uniqueIdentifiers(res.data).sort((a, b) => checkSort(a, b, 'campaign_group_name', 'asc'));
  await dispatch(setCampaignGroups(campaign_groups));

  return campaign_groups;
};

export const createCampaignGroup = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));

  const res = await Gemini.post(`/advertisers/${entity_id}/campaign_groups/create`, data);
  return res.data;
};
